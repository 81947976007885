import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { RichText } from './RichText';
import { RichTextJsonContent } from './RichTextJsonContent';
import type { RichTextComponents } from './RichTextJsonContent';
import type { RichTextBlock_block$key } from './__generated__/RichTextBlock_block.graphql';

const blockFragment = graphql`
  fragment RichTextBlock_block on RichTextBlock {
    textAlignment
    jsonContent {
      json
    }
  }
`;

type Props = {
  block: RichTextBlock_block$key;
  overrides?: RichTextComponents;
};

export const RichTextBlock: FC<Props> = ({ overrides, ...props }) => {
  const block = useFragment(blockFragment, props.block);

  return (
    <RichText alignment={block.textAlignment}>
      <RichTextJsonContent content={block.jsonContent} overrides={overrides} />
    </RichText>
  );
};
