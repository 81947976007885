import { graphql } from 'relay-runtime';
import styled from '@emotion/styled';
import { CtaButton } from '../flow';
import type { sharedProgramGroupItem_offer$key } from './__generated__/sharedProgramGroupItem_offer.graphql';

export const programGroupItemFragment = graphql`
  fragment sharedProgramGroupItem_offer on IBonusOffer {
    ...GeneratedBonusTitle_offer
    ...BonusBannerImage_offer
    bannerImage {
      ...BonusBannerImage_bannerImage
    }
    title
    # vertical layout
    ...OfferFlowSteps_data
    sellingPoints {
      ...SellingPoints_content
    }
  }
`;

export type ProgramGroupItemProps = {
  externalId: string;
  groupId?: string;
  offerId?: number;
  offer: sharedProgramGroupItem_offer$key;
  disableClick?: boolean;
};

export const JoinButton = styled(CtaButton)({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 50%)',
  width: 'max-content',
});
