/**
 * @generated SignedSource<<aae688e0f1ee601dfaf3fd7be0bd3d38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sharedProgramGroupItem_offer$data = {
  readonly bannerImage: {
    readonly " $fragmentSpreads": FragmentRefs<"BonusBannerImage_bannerImage">;
  } | null;
  readonly sellingPoints: {
    readonly " $fragmentSpreads": FragmentRefs<"SellingPoints_content">;
  } | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"BonusBannerImage_offer" | "GeneratedBonusTitle_offer" | "OfferFlowSteps_data">;
  readonly " $fragmentType": "sharedProgramGroupItem_offer";
};
export type sharedProgramGroupItem_offer$key = {
  readonly " $data"?: sharedProgramGroupItem_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"sharedProgramGroupItem_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sharedProgramGroupItem_offer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GeneratedBonusTitle_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BonusBannerImage_offer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "bannerImage",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BonusBannerImage_bannerImage"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferFlowSteps_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RichTextBlock",
      "kind": "LinkedField",
      "name": "sellingPoints",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SellingPoints_content"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IBonusOffer",
  "abstractKey": "__isIBonusOffer"
};

(node as any).hash = "c826801357c2e3a180fa94507fa61790";

export default node;
