import type { FC } from 'react';
import { Image } from '../image';

type ThumbsUpToastImageProps = {
  altText: string;
};

export const ThumbsUpToastImage: FC<ThumbsUpToastImageProps> = (props) => (
  <Image src="/static/images/thumbs-up.png" alt={props.altText} height={40} width={40} />
);
