import { toast } from 'react-toastify';
import { graphql, useFragment } from 'react-relay/hooks';
import type { ReactNode } from 'react';
import { useFlowRouter } from '@pafcloud/flow-router';
import { useTranslation } from '@pafcloud/i18n';
import { removeNullValues } from '@pafcloud/collection-utils';
import { useJoinProgram } from '../hooks';
import type { useProgramGroupProperties_offer$key } from './__generated__/useProgramGroupProperties_offer.graphql';
import type { useProgramGroupProperties_program$key } from './__generated__/useProgramGroupProperties_program.graphql';

export const offerGroupFragment = graphql`
  fragment useProgramGroupProperties_offer on BonusOffer {
    offerId
    group {
      groupExternalId: externalId
      programs {
        externalId
        ...sharedProgramGroupItem_offer
      }
    }
  }
`;

export const programGroupFragment = graphql`
  fragment useProgramGroupProperties_program on PublicBonusOffer {
    group {
      groupExternalId: externalId
      programs {
        externalId
        ...sharedProgramGroupItem_offer
      }
    }
  }
`;

export type RenderPosition = 'HERO' | 'MAIN' | 'FLOW';

/**
 * @param canJoinGroup: The program group is a public program, which can be joined by the player
 * @param firstInMain: The component is used as a hero and placed at the top of the page
 * @param disableClick: The program group is used and should not be interacted with
 * @param usedInHero: The component is used inside a hero and will thus use that background
 */
export type ProgramGroupProps = {
  offer: useProgramGroupProperties_offer$key | null;
  program: useProgramGroupProperties_program$key | null;
  canJoinGroup?: boolean;
  disableClick?: boolean;
  firstInMain?: boolean;
  usedInHero?: boolean;
  className?: string;
  renderPosition?: RenderPosition;
};

type UseProgramGroupPropertiesProps = {
  offer: ProgramGroupProps['offer'];
  program: ProgramGroupProps['program'];
  thumbsUpImage: ReactNode;
};

export const useProgramGroupProperties = (props: UseProgramGroupPropertiesProps) => {
  const offer = useFragment(offerGroupFragment, props.offer);
  const program = useFragment(programGroupFragment, props.program);
  const { t } = useTranslation(['bonus', 'common']);
  const joinProgram = useJoinProgram();
  const { closeFlow, openFlow } = useFlowRouter();

  const group = offer?.group || program?.group;

  if (group == null) {
    return null;
  }

  const handleJoinProgram = async () => {
    const response = await joinProgram({ externalId: group.groupExternalId });

    if (response.error === 'TIME_OUT') {
      toast.error(`${t('common:error.header')} - ${t('common:error.text')}`);
      return;
    }

    if (response.error) {
      toast.error(t('bonus:join.error.text'));
      return;
    }

    toast.success(props.thumbsUpImage);

    if (response.offers == null) {
      return closeFlow();
    }

    const joinedOffer = response.offers.find((playerOffer) => playerOffer?.publicOfferId === group.groupExternalId);

    if (joinedOffer != null) {
      void (async () => {
        await closeFlow();
        openFlow('bonus', { playerProgramId: joinedOffer.offerId });
      })();
    }

    return closeFlow();
  };

  const programs = [
    ...removeNullValues(offer?.group?.programs ?? []),
    ...removeNullValues(program?.group?.programs ?? []),
  ];

  return { groupExternalId: group.groupExternalId, offerId: offer?.offerId, programs, handleJoinProgram };
};
