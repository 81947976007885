import { toast } from 'react-toastify';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { useFlowRouter } from '@pafcloud/flow-router';
import { useJoinOfferGroup } from '../hooks';
import type { ProgramGroupItemProps } from './shared';

type UseHandleJoinProgramInGroupProps = {
  externalId: ProgramGroupItemProps['externalId'];
  groupId: ProgramGroupItemProps['groupId'];
  offerId: ProgramGroupItemProps['offerId'];
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  thumbsUpToastImage: ReactNode;
};

export const useHandleJoinProgramInGroup = (props: UseHandleJoinProgramInGroupProps) => {
  const { t } = useTranslation(['bonus', 'common', 'inbox']);
  const { openFlow, closeFlow } = useFlowRouter();
  const joinOfferGroup = useJoinOfferGroup();

  const handleJoinProgram = async () => {
    props.setIsLoading(true);

    if (props.offerId != null && props.groupId != null) {
      const response = await joinOfferGroup({
        externalId: props.externalId,
        groupExternalId: props.groupId,
        playerProgramGroupId: props.offerId,
      });

      if (response.error === 'TIME_OUT') {
        props.setIsLoading(false);
        return toast.error(`${t('common:error.header')} - ${t('common:error.text')}`);
      }

      if (response.error) {
        props.setIsLoading(false);
        return toast.error(t('bonus:join.error.text'));
      }

      if (response.success) {
        props.setIsLoading(false);
        toast.success(props.thumbsUpToastImage);

        const joinedOffer = response.offers?.find((responseOffer) => responseOffer?.publicOfferId === props.externalId);

        if (joinedOffer == null) {
          return closeFlow();
        }

        return openFlow('bonus', { playerProgramId: joinedOffer.offerId });
      }
    } else {
      return toast.error(`${t('common:error.header')} - ${t('common:error.text')}`);
    }
  };

  return handleJoinProgram;
};
