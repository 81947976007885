import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useJoinOfferGroupMutation } from './__generated__/useJoinOfferGroupMutation.graphql';

const createUseJoinOfferGroup = createUseMutation<useJoinOfferGroupMutation>(graphql`
  mutation useJoinOfferGroupMutation($input: JoinOfferGroupInput!) {
    joinOfferGroup(input: $input) {
      ... on JoinProgramSuccess {
        success
        bonusPage {
          ...ActiveOffers_bonuses
          ...AvailablePrograms_availablePrograms
          ...QueuedOffers_bonuses
        }
        offers {
          ...turnover_offer
          offerId
          publicOfferId
        }
      }
      ... on JoinProgramError {
        error
      }
    }
  }
`);

export const useJoinOfferGroup = createUseJoinOfferGroup((result) => {
  return result.joinOfferGroup;
});
